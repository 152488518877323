<template>
  <div>
        <kendo-grid
            ref="grid" 
            :data-source="dataSource"
            :filterable="filterableConfig"
            :sortable="true"
            :pageable="pageableConfig"
            :columns="columns"
            v-on:detailinit="detailInit"
            :resizable="true"
            >
        </kendo-grid>
  </div>
</template>


<script>
import { Grid, GridColumn } from '@progress/kendo-grid-vue-wrapper';
import { globalfunc } from '../../../../shared/GlobalFunction';
import { apiBaseUrl } from '../../../../infrastructure/constant/connection';
import BookingOrderServices from '../Script/BookingOrderServices.js';
import { kendo_grid } from '../../../../infrastructure/constant/variable';

export default {
    name: 'BookingOrderGridPerItem',
    components: {
        'kendo-grid' : Grid,
        'kendo-grid-column': GridColumn,
    },
    props: ['StorageId'],
    data: function () {
        var storageId = this.StorageId;
        return  {
            dataSource:  new kendo.data.DataSource ({
                page: 1,
                pageSize: kendo_grid.default_grid_pagesize,
                serverPaging: true,
                serverFiltering: true,
                serverSorting: true,
                transport: {
                    read: {
                        contentType: "application/json",
                        url: apiBaseUrl,
                        type: "POST",
                        data: function(e) {
                            var swapField = [
                                { field: "product_code", new_field: "\"booking_order_per_item\".\"product_code\"" },
                                { field: "item_name", new_field: "\"booking_order_per_item\".\"item_name\"" },
                                { field: "uom", new_field: "\"booking_order_per_item\".\"uom\"" },
                                { field: "conv_amount", new_field: "\"booking_order_per_item\".\"conv_amount\"" },
                                { field: "total_stock_sales", new_field: "\"ItemStockSales\".\"stock\"" },
                                { field: "total_quantity_base", new_field: "\"booking_order_per_item\".\"total_quantity_base\"" },
                                { field: "total_quantity_ready", new_field: "\"booking_order_per_item\".\"total_quantity_ready\"" },
                                { field: "storage_id", new_field: "\"ItemStockSales\".\"storage_id\"" },
                            ];
                            const paging = {
                                Page : {
                                    Limit: e.pageSize,Page: e.page
                                },
                                Filter : globalfunc.filterQueryBuilder(e.filter, swapField),
                                OrderBy : globalfunc.sortingQueryBuilder(e.sort, swapField)
                            }
                            const variables = {
                                id: storageId,
                                paging : paging
                            }
                            return { 
                                query: BookingOrderServices.readBookingOrderPerItemQuery(),
                                variables: variables
                            };
                        },
                        beforeSend: function (req) {
                            req.setRequestHeader('Authorization', window.$cookies.get('Token'));
                            req.setRequestHeader('Path', window.location.pathname);
                        }
                    },
                    parameterMap: function(options, operation) {
                        return  kendo.stringify({
                            query: options.query,
                            variables: options.variables
                        });
                    },
                    cache: false,
                },
                schema: {
                    data: function (response) {
                        if (response.data.GetBookingOrderPerItem == null) {
                            return [];
                        }
                        else {
                            return response.data.GetBookingOrderPerItem.booking_order_per_item;
                        }
                    },
                    total: function (response) {
                        if (response.data.GetBookingOrderPerItem == null) {
                            return 0;
                        }
                        else {
                            return response.data.GetBookingOrderPerItem.total;
                        }
                    },
                    model: {
                        fields: {
                            conv_amount: { type: "number" },
                            total_stock_sales: { type: "number" },
                            total_quantity_base: { type: "number" },
                            total_quantity: { type: "number" },
                            total_quantity_ready: { type: "number" },
                        }
                    }
                },
                // group: [
                //     {field: "type_detail_name"},
                // ]
            }),
            columns:  [
                // { field: "type_detail_name", title: "Tipe Detail", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" },
                //     groupHeaderTemplate: "Tipe Detail : #= value #"
                // },
                { field: "product_code", title: "Kode Barang", width: 150, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "item_name", title: "Nama Barang", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "storage_id", title: "Gudang", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "uom", title: "UOM", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "conv_amount", title: "Nilai Konversi", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-right" } },
                { field: "total_stock_sales", title: "Stock", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-right" }, format:"{0:N3}" },
                { field: "total_quantity", title: "Booking", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-right" } },
                { field: "total_quantity_ready", title: "Qty App", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-right" } },
            ],
            filterableConfig:  {
                extra: true,
                operators: kendo_grid.default_operator
            },
            pageableConfig: kendo_grid.default_pageable_config
        };
    },
    methods: {
        columnButton(){
            var customButton = `
                &nbsp;&nbsp;
                <button type="button" class="btn btn-success btn-sm rounded" id="AddButton">
                    <i class="fa fa-plus"></i> </span>
                </button> &nbsp;&nbsp;
            `;
            return this.$globalfunc.gridActionButton("Booking Order",customButton)
        },
        detailInit: async function (e) {
            var data = BookingOrderServices.dataToArrayPerItem(e.data);

            $('<div />').appendTo(e.detailCell).kendoGrid({
                ref: "BookingOrderDetailGrid",
                dataSource: {
                    transport: {
                        read: function (x) {
                            x.success(data);
                        },
                    },
                    parameterMap: function(options, operation) {
                        return kendo.stringify({
                            query: options.query,
                            variables: options.variables
                        });
                    },
                    schema: {
                        data: function (response) {
                            if (response == null)
                                return []
                            else
                                return response
                        },
                        total: function(response) {
                            if (response == null)
                                return 0
                            else
                                return response.length;
                        }
                    },
                    pageSize: kendo_grid.default_detail_grid_pagesize
                },
                scrollable: false,
                sortable: true,
                pageable: true,
                navigatable: true,
                columns: [
                    { field: 'customer_name', title: "Customer", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, editable: false, nullable: true },
                    { field: "uom", title: "UOM", width: 120, headerAttributes:{ style: "text-align:center; font-weight:bold;" }},
                    { field: "quantity", title: "Booking", width: 120, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-right" }},
                    { field: "quantity_remaining", title: "Remaining", width: 120, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-right" }},
                    { field: "quantity_ready", title: "Qty App", width: 120, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-right" }},
                    { field: "quantity_purchase_order", title: "Qty To PO", width: 120, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-right" }},
                ],
            });
        },
    }
}
</script>