import gql from 'graphql-tag';
import {globalfunc} from '../../../../shared/GlobalFunction';

class BookingOrderServices {
  readBookingOrderQuery(){
    var query = `
      query ($paging:ServerPaging) {
        GetBookingOrder (Paging:$paging) {
          booking_order_v {
            created_at
            created_by
            last_update
            booking_order_id
            purchase_order_id
            purchase_order_number
            item_id
            product_code
            item_name
            barcode
            type_detail_id
            uom
            base_uom
            conv_amount
            price_per_unit
            price
            vat_per_unit
            vat
            quantity
            quantity_base
            quantity_production
            quantity_ready
            quantity_current
            quantity_purchase_order
            stock_sales
            discount1
            discount2
            discount3
            discount4
            discount5
            discount_cash
            discount1_amount
            discount2_amount
            discount3_amount
            discount4_amount
            discount5_amount
            discount_cash_amount
            tax_amount
            customer_id
            customer_name
            storage_id
            item_total_all {
              item_id
              total_quantity
              total_quantity_purchase_order
              total_quantity_ready
            }
            customer_item_total_all {
              customer_id
              item_id
              total_quantity
              total_quantity_purchase_order
              total_quantity_ready
            }
          }
          total
        }
      }
    `;
    return query;
  }
  
  dataToArray(data){    
    var itemArray = [];
    var str = {
      customer_id: data.customer_id,
      customer_name: data.customer_name,
      type_detail_id: data.type_detail_id,
      quantity: data.quantity,
      quantity_ready: data.quantity_ready,
      quantity_purchase_order: data.quantity_purchase_order,
      created_at: data.created_at,
      created_by: data.created_by
    }
    itemArray.push(str);
    return itemArray;
  }

  async getStorageId(){
    var query = gql`
        query{
            GetStorage{
                storage_id
                storage_name
            }
        }
    `;
    var result = await globalfunc.defaultApolloQueryDefault(query);
    var storageData = [];
    for (let i = 0; i < result.data.GetStorage.length; i++) {
        var str = { 
          value: result.data.GetStorage[i].storage_id, 
          label: result.data.GetStorage[i].storage_id + ' (' + result.data.GetStorage[i].storage_name + ')'
        }
        storageData.push(str);
    }
    return storageData
  }

  readBookingOrderPerItemQuery(){
    var query = `
      query ($id:String, $itemType:String, $paging:ServerPaging) {
        GetBookingOrderPerItem (StorageID:$id, ItemType:$itemType,  Paging:$paging) {
          booking_order_per_item {
            item_id
            item_name
            item_type
            type_detail_id
            type_detail_name
            product_code
            uom
            conv_amount
            storage_id
            total_stock_sales
            total_quantity
            total_quantity_base
            total_quantity_production
            total_quantity_purchase_order
            total_quantity_ready
            booking_order_detail_per_item {
              item_id
              product_code
              customer_id
              customer_name
              uom
              conv_amount
              storage_id
              quantity
              quantity_base
              quantity_production
              quantity_purchase_order
              quantity_ready
              quantity_remaining
            }
          }
          total
        }
      }
    `;
    return query;
  }

  dataToArrayPerItem(data){
    var dataCustomer = data.booking_order_detail_per_item;

    var itemArray = [];
    for(let i = 0; i < dataCustomer.length; i++){
      var str = {
        customer_id: dataCustomer[i].customer_id,
        customer_name: dataCustomer[i].customer_name,
        uom: dataCustomer[i].uom,
        quantity: dataCustomer[i].quantity,
        quantity_base: dataCustomer[i].quantity_base,
        quantity_ready: dataCustomer[i].quantity_ready,
        quantity_purchase_order: dataCustomer[i].quantity_purchase_order,
        quantity_remaining: dataCustomer[i].quantity_remaining,
      }
      itemArray.push(str);
    };
    
    return itemArray;
  }
  
  validateDuplicateSelectedData(data){
    var array = data.split(';');

    let arrayObj = [];
    for (let i = 0; i < array.length; i++) {
      var detailArray = array[i].split('|');
      var obj = {
        booking_order_id: detailArray[0],
        purchase_order_id : detailArray[1],
        purchase_order_number : detailArray[2],
        quantity_ready : detailArray[3],
      }
      arrayObj.push(obj)
    }
    
    var unique = arrayObj
     .map(e => e['purchase_order_id'] + '|' + e['purchase_order_number'])
     .map((e, i, final) => final.indexOf(e) === i && i)
     .filter(obj=> arrayObj[obj]);

    if (unique.length > 1) {
      return "not match";
    }
    else {
      return arrayObj;
    }
  }

  async getBookingOrderDetail(id){    
    const variables = {
      id : id
    }
    
    var query = gql`
      query ($id:Int!) {
        GetBookingOrder (BookingOrderID:$id) {
          booking_order_v {
            created_at
            created_by
            last_update
            booking_order_id
            purchase_order_id
            purchase_order_number
            item_id
            product_code
            item_name
            barcode
            type_detail_id
            uom
            base_uom
            conv_amount
            price_per_unit
            price
            vat_per_unit
            vat
            quantity
            quantity_base
            quantity_production
            quantity_ready
            quantity_current
            quantity_purchase_order
            stock_sales
            discount1
            discount2
            discount3
            discount4
            discount5
            discount_cash
            discount1_amount
            discount2_amount
            discount3_amount
            discount4_amount
            discount5_amount
            discount_cash_amount
            tax_amount
            customer_id
            customer_name
            storage_id
          }
        }
      }
    `;
    var result = await globalfunc.defaultApolloQueryDefault(query, variables);
    
    return result.data.GetBookingOrder.booking_order_v[0];
  }

  async getBookingPurchaseOrder(bo_id, po_id){    
    const variables = {
      boId : bo_id,
      poId : po_id
    }
    var query = gql`query($boId:[Int!], $poId:Int!){
      GetBookingOrderPurchaseOrderableItemListV2 (bookingOrderIds:$boId, purchaseOrderId:$poId) {
        booking_order_id
        purchase_order_id
        item_id
        product_code
        item_name
        barcode
        type_detail_id
        base_uom
        uom
        conv_amount
        base_stock
        stock
        forcast_quantity
        ordered_quantity
        remaining_quantity
        quantity
        quantity_production
        quantity_ready
        quantity_purchase_order
        stock_sales
        price_per_unit
        price
        vat_per_unit
        vat
        discount1
        discount2
        discount3
        discount4
        discount5
        discount_cash
        discount1_amount
        discount2_amount
        discount3_amount
        discount4_amount
        discount5_amount
        discount_cash_amount
        tax_amount
      }
    }`;
    var result = await globalfunc.defaultApolloQueryDefault(query, variables);
    return result.data.GetBookingOrderPurchaseOrderableItemListV2;
  }

  editItemGridDataGenerator(data){
    var result = [];
    
    if(data != null){
        for (let i = 0; i < data.length; i++){
            var price_discount = data[i].discount1_amount + data[i].discount2_amount + data[i].discount3_amount + data[i].discount4_amount + data[i].discount5_amount + data[i].discount_cash_amount;
            var real_qty = data[i].conv_amount * (data[i].quantity_ready - data[i].quantity_purchase_order);
            var net = data[i].price_per_unit - price_discount;
            var total_price = (data[i].quantity_ready - data[i].quantity_purchase_order) * net;

            var str = {
                item_id : data[i].item_id,
                product_code : data[i].product_code,
                item_name : data[i].item_name,
                price : data[i].price_per_unit,
                price_discount : price_discount,
                net : net,
                qty : data[i].quantity_ready - data[i].quantity_purchase_order,
                real_qty : real_qty,
                total_price : total_price,
                discount1 : data[i].discount1,
                discount2 : data[i].discount2,
                discount3 : data[i].discount3,
                discount4 : data[i].discount4,
                discount5 : data[i].discount5,
                discount_cash : data[i].discount_cash,
                discount_amount_1 : data[i].discount1_amount,
                discount_amount_2 : data[i].discount2_amount,
                discount_amount_3 : data[i].discount3_amount,
                discount_amount_4 : data[i].discount4_amount,
                discount_amount_5 : data[i].discount5_amount,
                discount_amount_cash : data[i].discount_cash_amount,
                stock : 0,
                conv_amount : data[i].conv_amount,
                uom : data[i].uom,
                type_detail_id: data[i].type_detail_id
            };
            if (data[i].quantity_ready > 0) {
                result.push(str)
            }
        }
    }
    
    return result;
  }

  async editQuery(variables){
    var query = gql`
      mutation ($id:Int!, $data:NewBookingOrder!) {
        UpdateBookingOrder (BookingOrderID:$id, NewBookingOrder:$data)
      }
    `;
      
    return await globalfunc.defaultApolloMutationDefault(query, variables)
  }

  async deleteQuery(variables){
    var query = gql`
      mutation ($id:Int!) {
        DeleteBookingOrder (BookingOrderID:$id)
      }
    `;
      
    return await globalfunc.defaultApolloMutationDefault(query, variables)
  }

  async getItemStock(itemId, storageId, uom){
    const variables = {
        item_id : itemId,
        storage_id : storageId
    }
    var query = gql`query($storage_id:String, $item_id:String){
      GetItemStockV2(StorageId: $storage_id, ItemId: $item_id){
        item_stock{
          stock
          base_uom
          item_stock_detail{
            uom
            stock
          }
        }
      }
    }`;
    
    var result = await globalfunc.defaultApolloQueryDefault(query, variables);
    var stock = 0;
    if(result.data.GetItemStockV2.item_stock != null){
      var data = result.data.GetItemStockV2.item_stock[0].item_stock_detail;
      stock = data.find(c => c.uom == uom).stock;
    }

    return stock;
}

}

export default new BookingOrderServices();
